export const QuestionsListItems = [
  {
    label: "Are the skills listed in this profile consistent?",
    value: {}
  },
  {
    label: "Can you describe your experience and role when working in a team setting?",
    value: {}
  },
  {
    label: "Considering any inconsistencies in experiences, education, or skills, do you suspect any falsehoods in this profile?",
    value: {}
  },
  {
    label: "Does the candidate have a driving license?",
    value: {}
  },
  {
    label: "Does the candidate have certifications or additional qualifications relevant to a data scientist?",
    value: {}
  },
  {
    label: "Does the candidate have experience in collaborative work with multicultural teams?",
    value: {}
  },
  {
    label: "Does the candidate have professional recommendations or references available?",
    value: {}
  },
  {
    label: "Does the candidate have volunteer or community engagement experience?",
    value: {}
  },
  {
    label: "Explain how your career began?",
    value: {}
  },
  {
    label: "How could the candidate improve their CV to make it more attractive for a developer position ?",
    value: {}
  },
  {
    label: "How do you think your current co-workers would describe your work style and personality?",
    value: {}
  },
  {
    label: "How does the candidate manage stress and pressure at work ?",
    value: {}
  },
  {
    label: "List the titles of volunteer or community engagement experiences.",
    value: {}
  },
  {
    label: "Regarding customer interactions: Could you provide specific examples of your experience in working closely with customers?",
    value: {}
  },
  {
    label: "Summarize the candidate's career path",
    value: {}
  },
  {
    label: "Summarize the candidate's strengths",
    value: {}
  },
  {
    label: "What could be an invented element in this profile?",
    value: {}
  },
  {
    label: "What is the candidate's most significant professional achievement?",
    value: {}
  },
  {
    label: "What is the candidate's name?",
    value: {}
  },
  {
    label: "What is the candidate's phone number?",
    value: {}
  },
  {
    label: "What is the most surprising aspect of this profile?",
    value: {}
  },
  {
    label: "What is the most unexpected experience or skill listed in this profile?",
    value: {}
  },
  {
    label: "What is the name of the company from the candidate's last experience?",
    value: {}
  },
  {
    label: "What is the position from the candidate's last experience?",
    value: {}
  },
  {
    label: 'What is the position of the candidate who is not in an internship and for whom this is their first job?',
    value: {}
  },
  {
    label: "What were your primary responsibilities  in your previous role?",
    value: {}
  },
  {
    label: "What would you consider to be your greatest professional strength?",
    value: {}
  },
  {
    label: "When did the candidate complete their studies? (year)",
    value: {}
  },
]