import React, {useCallback} from "react"
import Select, { components } from 'react-select';
import { motion } from "framer-motion";
import styled from "styled-components"
import { QuestionsListItems }  from './questionsList';
import { useDropzone } from 'react-dropzone'
import XMark from "components/icons/xMark";
import CloudArrowUp from "components/icons/cloudArrowUp";


const DivBox = styled.div`
  border: 1.2px dashed #9DB9D6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  align-items: center;
  min-height: 10rem;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  background: #FFF;
`
const DropzoneTextDescription = styled.div`
  font-size: 1rem;
  color: #9DB9D6;
  margin-top: 1rem;
  `
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transition: transform 0.15s linear;
  &:hover {
      transform: scale(1.1);
      transition: transform 0.15s linear;
      }
  `
const ShadowBox = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 50%;
  padding: .5rem;
  background: #fafafa;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
`

const FileInfoDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  span {
    margin: 0.5rem;
  }
`

const SelectedQuestions = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const SelectedLocation = styled.div`
  display: flex;
  align-items: center;
  background-color: #eaffff; 
  color: #1dafb6;
  border: 1px solid #1dafb6;
  border-radius: 4px; 
  padding: 8px 12px; 
  gap: 8px;
  font-size: 14px;

`;

const RemoveButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default function FormStep({ 
  profileFile,
  setProfileFile,
  questions,
  setQuestions,
  dControls
}) {

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
    // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(binaryStr)
    }
    reader.readAsArrayBuffer(file)
    setProfileFile(file)
    })
  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  const handleSelectQuestion = (selectedOptions) => {
    setQuestions(selectedOptions || []);
  }

  const handleRemoveQuestion = (questionToRemove) => {
    const newQuestions = questions.filter(item => item.value !== questionToRemove.value);
    setQuestions(newQuestions);
  }

  const CustomValueContainer = ({ children, ...props }) => {
    const hasInputValue = !!props.selectProps.inputValue;

    return (
      <components.ValueContainer {...props}>
        {!hasInputValue && (
          <components.Placeholder {...props}>
            {props.selectProps.placeholder}
          </components.Placeholder>
        )}
        {React.Children.map(children, child =>
          child && child.type !== components.Placeholder ? child : null
        )}
      </components.ValueContainer>
    );
  };

  const customComponents = {
    MultiValue: () => null, 
    ValueContainer: CustomValueContainer
  };

  return (
    <motion.div
      className="control"
      initial={{ opacity: 0, x: 80 }}
      animate={dControls}
    >
      <div className="control">
        <div className="label">Upload resume</div>
          <div className="subtitle">
            Upload a resume/CV extract, ask a question based on a Profile object with HrFlow.ai Profile Asking API.
          </div>
          <DivBox {...getRootProps()}>
            <input {...getInputProps()} />
            <ShadowBox>
              <IconWrapper>
                <CloudArrowUp color="#9DB9D6" width="30" height="30" />
              </IconWrapper>
            </ShadowBox>
            <DropzoneTextDescription>
              Drag and drop your resume here or <span style={{ color: "#60a5fa"}}>click</span> to upload your resume.
            </DropzoneTextDescription>
            {profileFile && (
              <FileInfoDiv>
                <span>{profileFile.name}</span>
                <span>{(profileFile.size/1024).toFixed(2) + 'Kb'}</span>
              </FileInfoDiv>
            )}
        </DivBox>
      </div>
      <div className="control">
        <label className="label">Questions</label>
        <Select
          className="basic-single"
          classNamePrefix="select"
          onChange={handleSelectQuestion}
          placeholder="Select question" 
          value={questions}
          isClearable={false}
          isSelectable={false}
          isRtl={false}
          options={QuestionsListItems}
          isMulti
          components={customComponents}
        />
        <SelectedQuestions>
          {questions.map((questionItem, index) => (
            <SelectedLocation key={index}>
              <RemoveButton onClick={() => handleRemoveQuestion(questionItem)}>
                <XMark />
              </RemoveButton>
              {questionItem.label}
            </SelectedLocation>
          ))}
        </SelectedQuestions>
      </div>
    </motion.div>
  )
}