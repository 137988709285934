import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"
import { postProfileAsking, getProfileAsking } from "store/actions/profile"
import { getSearchParams } from "gatsby-query-params";

import Helmet from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

import GlobalStateProvider from "context/provider"
import ContentWrapper from "styles/contentWrapper"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/category/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/category/demos"
import Grid from "components/sections/grid"
import Breadcumbs from "components/common/breadcumbs"

import { seoTitleSuffix } from "config"
import Asking from "components/profile/asking"
import peopleImg from "content/images/people.svg"
import PrimaryCard from "components/common/primaryCard"
import ProfileUnfoldingImg from "components/assets/profile-unfolding.png";


const resumeParsing = "https://hrflow-ai.imgix.net/labs/resume-parsing.png?w=350&h=350&fit=clip"
const profileSearching = "https://hrflow-ai.imgix.net/labs/profile-searching.png?w=350&h=350&fit=clip"
const profileScoring = "https://hrflow-ai.imgix.net/labs/recruiter-copilot.png?w=350&h=350&fit=clip"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`

const ProfileAskingPage = (
  {
    data,
    postProfileAsking,
    getProfileAsking,
    profileAsking,
    profileAskingResult
  }) => {
  const { frontmatter } = data.index.edges[0].node
  const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter
  const queryParams = getSearchParams()

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }


  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Breadcumbs
          color="#22B5F3"
          text="Profile analysis"
          link="/profile-analysis/"
          logo={peopleImg}
        />
        <Hero
          content={data.hero.edges}
          color="#22B5F3"
          resultPage={queryParams?.key}
          docs="https://developers.hrflow.ai/reference/parse-a-resume"
        />
        <Grid>
          <Asking
            queryParams={queryParams}
            postProfileAsking={postProfileAsking}
            response={profileAsking}
            getProfileAsking={getProfileAsking}
            profileAskingResult={profileAskingResult}
          />
        </Grid>
        <Clients content={data.clients.edges} category />
        <Demos content={data.demos.edges}>
          <StyledGrid className="mt-2">
            <PrimaryCard
              image={resumeParsing}
              color="#22B5F3"
              title="Resume parsing"
              link="/profile-analysis/parsing/"
              subtitle="Upload a resume/CV extract its semantic entities — such as names, emails, phones, locations, companies, schools, degrees, job titles, tasks, skills, interests, durations, dates and more."
            />  
            <PrimaryCard
              image={profileSearching}
              color="#22B5F3"
              title="Profile Searching"
              link="/profile-analysis/searching/"
              codeUrl="https://codepen.io/HrFlow/pen/eYdOjPP"
              subtitle="Talent Pool/Marketplace Search Demo powered by HrFlow.ai Profile Searching API."
            />
            <PrimaryCard
              image={profileScoring}
              color="#22B5F3"
              title="Recruiter Copilot"
              link="/profile-analysis/recruiter-copilot/"
              subtitle="Our AI-powered Recruiter Copilot is designed to help you or your users identify the most qualified candidates for a job opening."
            />
            <PrimaryCard
              image={ProfileUnfoldingImg}
              color="#22B5F3"
              title="Profile Unfolding"
              link="/profile-analysis/unfolding/"
              subtitle="Predict the future experiences and educations of a profile. with HrFlow.ai Profile Unfolding API."
            /> 
          </StyledGrid>
        </Demos>
        {/* <Helmet>
          <script src={withPrefix('script.js')} type="text/javascript" />
          <noscript>
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3286450&fmt=gif" />
          </noscript>
        </Helmet> */}
      </Layout>
    </GlobalStateProvider>
  )
}

ProfileAskingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  profileAsking: state.profile.profileAsking,
  profileAskingResult: state.profile.profileAskingResult,
})

export default connect(mapStateToProps, { postProfileAsking, getProfileAsking })(ProfileAskingPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/profile/asking/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/profile/asking/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    demos: allMdx(filter: { fileAbsolutePath: { regex: "/profile/asking/demos/" } }) {
      edges {
        node {
          frontmatter {
            label
            title
            titlePrefix
            description
          }
        }
      }
    }
  }
`