import React, {useState, useEffect, useCallback} from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import Input from "components/common/input"
import ArrowRight from "components/icons/arrowRight"
import Sync from "components/icons/sync"
import Envolope from "components/icons/envolope"

import ErrorMessage from "components/common/errorMessage"
import StyledBtn from "styles/button"
import Cgu from "components/common/cgu"
import FormStep from "./formStep"


const StyledForm = styled.form`
  background: white;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  min-height: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
  .form__body {
    padding: 1.5rem;
    .control {
      margin-bottom: 1rem;
    }
    .label {
      color: ${({ theme }) => theme.colors.zodiacLight};
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: 0.55rem;
    }

    .subtitle {
      color: ${({ theme }) => theme.colors.tertiary};
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1.2rem;
    }
    .alert {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 3rem;
      text-align: center;
      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        a:first-of-type {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        a {
          width: 100%;
        }
        margin-top: 0.7rem;
      }
      .logo {
        height: 3.56rem;
        width: 3.56rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E7F0FF;
        border-radius: 0.22rem;
        margin-bottom: 1rem;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        .buttons {
          flex-direction: row;
          a:first-of-type {
            margin-right: 1rem;
            margin-bottom: 0;
          }
          a {
            width: unset;
          }
        }
      }
    }
  }
  .form__footer {
    padding: 1.3rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #EBEFF9;
  }
`

const StyledButton = styled(StyledBtn).attrs({
  as: "button"
})`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #22B5F3;
  border: none;
  cursor: pointer;
  min-width: 10rem;
`

const StyledButtonLink = styled(StyledBtn)`
  padding: 0 1.3rem;
  height: 3.12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 0.375rem;
  background: #22B5F3;
  border: none;
  cursor: pointer;
`

const StyledButtonLinkWhite = styled(StyledButtonLink)`
  color: #22B5F3;
  background: white;
  border: 1px solid #22B5F3;
`

const Form = props => {
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState("")
  const [profileFile, setProfileFile] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const dControls = useAnimation()
  const eControls = useAnimation()
  const rControls = useAnimation()
  useEffect(() => {
    const StepTransitionSequence = async () => {
        await dControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await eControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        await rControls.start({
          opacity: 1,
          x: 0,
          transition: { delay: 0.1 },
        })
        // Animate underlining to hover state
    }
    StepTransitionSequence()
  }, [eControls, dControls, rControls, step])

  useEffect(() => {
    if(!firstLoad) {
      if(props.response?.s) {
        setStep(3)
        setError(false)
      }
      if(props.response.f) {
        setStep(3)
        setError(true)
      }
    }
    return () => {
      setFirstLoad(false)
    };
  }, [props.response])
  
  useEffect(() => {
    setStep(1)
  }, [])

  useEffect(() => {
    if (props.profileAskingResult?.s) {
  
      setProfileFile({
        name: props.profileAskingResult?.payload?.attachments[1]?.original_file_name,
        size: props.profileAskingResult?.payload?.attachments[1]?.file_size
      });

      const formatedQuestions = props.profileAskingResult?.payload?.questions?.map((question) => ({
        label: question,
        value: {} 
      }));
      setQuestions(formatedQuestions);
    }
  }, [props.profileAskingResult]);

  const addProfileFile = e => {
    e.preventDefault()
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6Lcbn24bAAAAADaaYP7VaaG6FYlzzFT0sAZUvqjg", { action: 'submit' }).then(token => {
        if(email) {
          submitData(token);
        } else {
          setLoading(false);
        }
      });
    });
  }

  const submitData = token => {
    // call a backend API to verify reCAPTCHA response
    fetch('https://api-workflows.hrflow.ai/teams/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/92a6071d499f12b0d37c3f33c3ae9acca8f71955', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "g-recaptcha-response": token
      })
    }).then(res => res.json()).then(res => {
      const jsonResponse = JSON.parse(res.stdout)
      if(jsonResponse.success && jsonResponse.score >= 0.7) {

        const formatedQuestions = questions.map(question => question.label);

        props.postProfileAsking({
          file: profileFile, 
          email: email, 
          questions: formatedQuestions,
          marketing_data: props.params, 
          referrer: document.referrer,
          profile_file: [{name: 'file_name', value: profileFile.name}, {name: 'file_size', value: profileFile.size}]
        })
      } else {
        setError(true)
      }
      setLoading(false);
    });
  }
  
  return (
    <StyledForm
      color="#22B5F3"
      onSubmit={e => {
          e.preventDefault();
          if(step === 1) {
            setStep(2)
          } else {
            addProfileFile(e)
          }
        }
      }
    >
      <div className="form__body">
        { step === 1 && (
          <FormStep
            profileFile={profileFile}
            setProfileFile={setProfileFile}
            questions={questions}
            setQuestions={setQuestions}
            dControls={dControls }
          />
        )}
        {step === 2 && (
          <motion.div
            className="control"
            initial={{ opacity: 0, x: 80 }}
            animate={eControls}
          >
            <div className="label">Enter your work email</div>
            <div className="subtitle">
              In order to receive your result, please enter your work email.
            </div>
            <Input 
              changed={event => setEmail(event.target.value)}
              type="input"
              placeholder="Enter your email"
            />
            <Cgu />
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            className="alert"
            initial={{ opacity: 0, x: 80 }}
            animate={rControls}
          >
            { error ?
              (
                <ErrorMessage
                  title="Something went wrong"
                  subtitle="Please retry again later."
                  type="error"
                  min="true"
                />
              )
              :
              (
                <>
                  <div className="logo">
                    <Envolope color="#2A7CF8" />
                  </div>
                  <div className="label">We sent your results on your email address</div>
                  <div className="subtitle">
                    Please click on the link in the email to access your results
                  </div>
                </>
              )
            }
            <div className="buttons">
              <StyledButtonLinkWhite
                to="/profile-analysis/asking/"
                onClick={() => setStep(1)}
              >
                <span className="mr-1">Try another resume</span><Sync color="#22B5F3"/>
              </StyledButtonLinkWhite>
              <StyledButtonLink
                  to="https://hrflow.ai/signup"
                  target="_blank"
                  rel="noreferrer"
              >
                <span className="mr-1">Register now</span><ArrowRight color="#FFF" />
              </StyledButtonLink>
            </div>
          </motion.div>
        )}
      </div>
      { step !== 3 && (
          <div className="form__footer">
            <StyledButton
                disabled={props.response.r || loading || (step === 1 && !profileFile)}
                loading={props.response.r || loading}
            >
              { (props.response.r || loading) ?
                (
                  <div style={{display: 'flex', justifyContent: 'center', width:"100%"}}>
                    <span className="loader"></span>
                  </div>
                )
                :
                (
                  <>
                    <span className="mr-1">Analyze</span><ArrowRight color="#FFF" />
                  </>
                )
              }
            </StyledButton>
          </div>
        )
      }
    </StyledForm>
  )
}


export default Form
