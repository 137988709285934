import React from "react"
import styled from "styled-components"
import Form from "./form/index"
import Result from "./result/index"

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`


const Asking = (props) => {
  return (
    <StyledContainer
      color="#22B5F3"
    >
      <Form
        postProfileAsking={props.postProfileAsking}
        response={props.response}
        profileAskingResult={props.profileAskingResult}
        params={props.queryParams}
      />
      {props.queryParams?.key ?
        (
          <Result 
            getProfileAsking={props.getProfileAsking}
            profileAskingResult={props.profileAskingResult}
            email={props.queryParams.email}
            profileAskingKey={props.queryParams.key}
          />
        )
        :
        (
          ''
        )
      }
    </StyledContainer>
  )
}


export default Asking